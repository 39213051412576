<template>
  <div class="AccountSaved" >
    <div class="container-fluid text-center">
      <h1
        class="gavc-h1 text-center"
        style="margin-top: 150px;"
      >
        Félicitations, votre compte a bien été créé 🙂
      </h1>
      <p class="gavc-chapo mt-4">
        Vous pouvez dès maintenant vous connecter, compléter vos informations et utiliser pleinement l'application Garance-à-vos-côtés
      </p>

      <div class="row mt-2" style="margin-bottom: 200px;">
        <div class="col-12 text-center">
          <button
            class="gavc-btn mt-2"
            @click="$router.push('/se-connecter')">
            Je me connecte
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountSaved',
  mounted () {
    if (this.$cookies.get('cookie-analysis')) {
      const tagManagerScript = document.createElement('script')
      tagManagerScript.setAttribute('async', true)
      tagManagerScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=AW-10883104919")

      const tagManagerConfigScript = document.createElement('script')
      const tagManagerConfigScriptInlineCode = document.createTextNode(
        `window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-10883104919');`
      )
      tagManagerConfigScript.appendChild(tagManagerConfigScriptInlineCode);

      this.tagManagerScript = document.body.appendChild(tagManagerScript)
      this.tagManagerConfigScript = document.body.appendChild(tagManagerConfigScript)
    }

    if (this.$cookies.get('cookie-social-network-analyses')) {
      window.fbq('track', 'Inscription')
    }
  },
  beforeDestroy() {
    if (this.$cookies.get('cookie-analysis')) {
      document.body.removeChild(this.tagManagerScript)
      document.body.removeChild(this.tagManagerConfigScript)
    }
  }
}
</script>
